
.roleTable[data-v-77d17aaa] .el-collapse-item__header {
  font-size: 14px;
  color: #999999;
  font-weight: bold;
  padding: 0 10px 0 20px;
}
.roleTable[data-v-77d17aaa] .el-icon-document {
  font-size: 20px;
  margin-right: 8px;
}
.roleTable[data-v-77d17aaa] .el-icon-arrow-right {
  font-size: 13px;
  color: #303133;
}
